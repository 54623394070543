import { isStringEmpty } from "./Common";

export const AppEnvironments = {
    Local: "local",
    Development: "development",
    Test: "test",
    Staging: "staging",
    Production: "production"
}

/**
 * If App environment set correctly it will return the specified app environment otherwise it will return "production" environment 
 * @returns Current application environment
 */
export function getCurrentAppEnvironment(): string {
    let env = process.env.REACT_APP_ENVIRONMENT;
    if (!env || isStringEmpty(env))
        return AppEnvironments.Production;

    return Object.values(AppEnvironments).indexOf(env.toLowerCase()) > -1
        ? env.toLowerCase()
        : AppEnvironments.Production;
}

/**
 * Shecks for the specified app environment
 * @param appEnvirontment to be checked
 * @returns true if specified app environment matchs otherwise false
 */
 export function checkAppEnvironment(appEnvirontment: string): boolean {
    return appEnvirontment === getCurrentAppEnvironment();
}