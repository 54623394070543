//This is auto generated enum
export enum I18 {
    app_brandname = "app_brandname",
    app_header_signin = "app_header_signin",
    app_header_signout = "app_header_signout",
    app_footer_disclaimer = "app_footer_disclaimer",
    app_footer_disclaimer_body = "app_footer_disclaimer_body",
    app_footer_copyright = "app_footer_copyright",
    app_footer_version = "app_footer_version",
    app_footer_software_licenses = "app_footer_software_licenses",
    home_title_article_tools = "home_title_article_tools",
    home_title_case_studies = "home_title_case_studies",
    home_harmonics_description = "home_harmonics_description",
    home_estimate_harmonics = "home_estimate_harmonics",
    home_login = "home_login",
    home_my_project = "home_my_project",
    home_my_project_additional_text = "home_my_project_additional_text",
    home_quick_estimate = "home_quick_estimate",
    home_quick_estimate_additional_text = "home_quick_estimate_additional_text",
    home_estimate_harmonics_modal_title = "home_estimate_harmonics_modal_title",
    home_download_excel = "home_download_excel",
    login_redirect_auth_portal = "login_redirect_auth_portal",
    login_redirect_harmonic_portal = "login_redirect_harmonic_portal",
    login_redirect_signout = "login_redirect_signout",
    login_waiting_message = "login_waiting_message",
    login_unauthorized_title = "login_unauthorized_title",
    login_unauthorized_message = "login_unauthorized_message",
    login_session_timeout_title = "login_session_timeout_title",
    login_session_timeout_message = "login_session_timeout_message",
    configuration_page_save_modalpopup_title = "configuration_page_save_modalpopup_title",
    configuration_page_save_modalpopup_header = "configuration_page_save_modalpopup_header",
    project_name = "project_name",
    optionalText_project_name_preferred = "optionalText_project_name_preferred",
    configuration_name = "configuration_name",
    optional_text_configuration_name_preferred = "optional_text_configuration_name_preferred",
    configuration_page_save_modalpopup_label_text_version_number = "configuration_page_save_modalpopup_label_text_version_number",
    configuration_page_save_modalpopup_optional_text_version_number = "configuration_page_save_modalpopup_optional_text_version_number",
    project_page_project_and_configuration_modalpopup_title = "project_page_project_and_configuration_modalpopup_title",
    project_page_project_and_configuration_modalpopup_success_notification_text = "project_page_project_and_configuration_modalpopup_success_notification_text",
    optioal_text_project_name = "optioal_text_project_name",
    optioal_text_configuration_name = "optioal_text_configuration_name",
    project_page_modalpopup_label_text_contact_person = "project_page_modalpopup_label_text_contact_person",
    project_page_modalpopup_optioal_text_contact_person = "project_page_modalpopup_optioal_text_contact_person",
    project_page_modalpopup_label_text_country = "project_page_modalpopup_label_text_country",
    project_page_modalpopup_optioal_text_country = "project_page_modalpopup_optioal_text_country",
    project_page_modalpopup_label_text_Industry = "project_page_modalpopup_label_text_Industry",
    project_page_modalpopup_optioal_text_Industry = "project_page_modalpopup_optioal_text_Industry",
    project_page_modalpopup_label_text_company = "project_page_modalpopup_label_text_company",
    project_page_modalpopup_optioal_text_company = "project_page_modalpopup_optioal_text_company",
    project_page_new_project_modalpopup_title = "project_page_new_project_modalpopup_title",
    project_page_new_project_modalpopup_success_notification_text = "project_page_new_project_modalpopup_success_notification_text",
    project_page_edit_project_modalpopup_success_notification_text = "project_page_edit_project_modalpopup_success_notification_text",
    project_page_edit_customer_information_modalpopup_title = "project_page_edit_customer_information_modalpopup_title",
    project_page_rename_configuration_modalpopup_title = "project_page_rename_configuration_modalpopup_title",
    project_page_rename_configuration_modalpopup_success_notification_text = "project_page_rename_configuration_modalpopup_success_notification_text",
    rename = "rename",
    edit = "edit",
    project_page_create_new_configuration_modalpopup_title = "project_page_create_new_configuration_modalpopup_title",
    project_page_create_new_configuration_modalpopup_success_notification_text = "project_page_create_new_configuration_modalpopup_success_notification_text",
    create = "create",
    creating = "creating",
    project_page_delete_project_modalpopup_title = "project_page_delete_project_modalpopup_title",
    project_page_delete_project_success_notification_text = "project_page_delete_project_success_notification_text",
    project_page_delete_configuration_success_notification_text = "project_page_delete_configuration_success_notification_text",
    project_page_delete_project_modalpopup_action_button = "project_page_delete_project_modalpopup_action_button",
    project_page_delete_project_modalpopup_loading_action_button = "project_page_delete_project_modalpopup_loading_action_button",
    project_page_delete_project_modalpopup_content_text = "project_page_delete_project_modalpopup_content_text",
    project_page_delete_project_modalpopup_confirmationt_text = "project_page_delete_project_modalpopup_confirmationt_text",
    project_new_project_button_label = "project_new_project_button_label",
    project_new_project_button_description = "project_new_project_button_description",
    project_all_project_label = "project_all_project_label",
    project_info_project_information_label = "project_info_project_information_label",
    project_info_contact_person_label = "project_info_contact_person_label",
    project_info_country_label = "project_info_country_label",
    project_info_industry_label = "project_info_industry_label",
    project_info_company_label = "project_info_company_label",
    project_info_configurations_label = "project_info_configurations_label",
    project_info_new_configuration_button_label = "project_info_new_configuration_button_label",
    project_info_configurations_description = "project_info_configurations_description",
    project_info_version_label = "project_info_version_label",
    duplicate = "duplicate",
    delete = "delete",
    save = "save",
    more = "more",
    add = "add",
    adding = "adding",
    cancel = "cancel",
    update = "update",
    export = "export",
    updating = "updating",
    upload = "upload",
    uploading = "uploading",
    home = "home",
    download = "download",
    info_page_version_history_header = "info_page_version_history_header",
    info_page_software_licenses_header = "info_page_software_licenses_header",
    info_page_disclaimer_header = "info_page_disclaimer_header",
    configuration_page_active_harmonic_filter_modal_popup_title = "configuration_page_active_harmonic_filter_modal_popup_title",
    configuration_page_active_harmonic_filter_modal_popup_edit_title = "configuration_page_active_harmonic_filter_modal_popup_edit_title",
    configuration_page_label_id_tag = "configuration_page_label_id_tag",
    configuration_page_optional_text_id_tag = "configuration_page_optional_text_id_tag",
    configuration_page_optional_text_id_tag_inline_edit_popup = "configuration_page_optional_text_id_tag_inline_edit_popup",
    configuration_page_label_total_rated_current = "configuration_page_label_total_rated_current",
    configuration_page_optional_text_total_rated_current = "configuration_page_optional_text_total_rated_current",
    configuration_page_assumption_notes_modal_popup_title = "configuration_page_assumption_notes_modal_popup_title",
    configuration_page_assumption_notes_modal_popup_header = "configuration_page_assumption_notes_modal_popup_header",
    configuration_page_export_configuration_modal_popup_title = "configuration_page_export_configuration_modal_popup_title",
    configuration_page_export_configuration_modal_popup_label_file_name = "configuration_page_export_configuration_modal_popup_label_file_name",
    configuration_page_export_configuration_modal_popup_optional_text_file_name = "configuration_page_export_configuration_modal_popup_optional_text_file_name",
    configuration_page_linear_load_modal_popup_title = "configuration_page_linear_load_modal_popup_title",
    configuration_page_linear_load_modal_popup_edit_title = "configuration_page_linear_load_modal_popup_edit_title",
    configuration_page_linear_load_modal_popup_text_transformer = "configuration_page_linear_load_modal_popup_text_transformer",
    configuration_page_linear_load_modal_popup_text_transformer_edit = "configuration_page_linear_load_modal_popup_text_transformer_edit",
    configuration_page_linear_load_modal_popup_text_genset = "configuration_page_linear_load_modal_popup_text_genset",
    configuration_page_linear_load_modal_popup_text_genset_edit = "configuration_page_linear_load_modal_popup_text_genset_edit",
    configuration_page_linear_load_modal_popup_header = "configuration_page_linear_load_modal_popup_header",
    configuration_page_linear_load_modal_popup_section_header_max_percentage_load_genset = "configuration_page_linear_load_modal_popup_section_header_max_percentage_load_genset",
    configuration_page_linear_load_modal_popup_section_header_max_percentage_load_transformer = "configuration_page_linear_load_modal_popup_section_header_max_percentage_load_transformer",
    configuration_page_linear_load_modal_popup_section_header_motor_loads = "configuration_page_linear_load_modal_popup_section_header_motor_loads",
    configuration_page_linear_load_modal_popup_section_header_soft_starters_loads = "configuration_page_linear_load_modal_popup_section_header_soft_starters_loads",
    configuration_page_linear_load_modal_popup_section_header_kva_loads = "configuration_page_linear_load_modal_popup_section_header_kva_loads",
    configuration_page_linear_load_modal_popup_section_header_resistive_loads = "configuration_page_linear_load_modal_popup_section_header_resistive_loads",
    configuration_page_linear_load_modal_popup_section_header_current_loads = "configuration_page_linear_load_modal_popup_section_header_current_loads",
    configuration_page_linear_load_modal_popup_radio_specific = "configuration_page_linear_load_modal_popup_radio_specific",
    configuration_page_linear_load_modal_popup_radio_max_percentage_load_transformer = "configuration_page_linear_load_modal_popup_radio_max_percentage_load_transformer",
    configuration_page_linear_load_modal_popup_radio_max_percentage_load_genset = "configuration_page_linear_load_modal_popup_radio_max_percentage_load_genset",
    configuration_page_label_total_kva_loads = "configuration_page_label_total_kva_loads",
    configuration_page_optional_text_total_kva_loads = "configuration_page_optional_text_total_kva_loads",
    configuration_page_label_total_average_power_factor = "configuration_page_label_total_average_power_factor",
    configuration_page_optional_text_average_power_factor = "configuration_page_optional_text_average_power_factor",
    configuration_page_label_total_percentage_kva_loads = "configuration_page_label_total_percentage_kva_loads",
    configuration_page_optional_text_total_percentage_kva_loads = "configuration_page_optional_text_total_percentage_kva_loads",
    configuration_page_label_total_motor_loads = "configuration_page_label_total_motor_loads",
    configuration_page_optional_text_total_motor_loads = "configuration_page_optional_text_total_motor_loads",
    configuration_page_label_total_soft_starterloads = "configuration_page_label_total_soft_starterloads",
    configuration_page_optional_text_totalsoft_starterloads = "configuration_page_optional_text_totalsoft_starterloads",
    configuration_page_optional_text_total_kva_loads_of_other_loads = "configuration_page_optional_text_total_kva_loads_of_other_loads",
    configuration_page_label_total_resistive_loads = "configuration_page_label_total_resistive_loads",
    configuration_page_optional_text_total_resistive_loads = "configuration_page_optional_text_total_resistive_loads",
    configuration_page_label_total_current_loads = "configuration_page_label_total_current_loads",
    configuration_page_optional_text_total_current_loads = "configuration_page_optional_text_total_current_loads",
    configuration_page_lv_genset_modal_popup_title = "configuration_page_lv_genset_modal_popup_title",
    configuration_page_label_frequency = "configuration_page_label_frequency",
    configuration_page_optional_text_frequency = "configuration_page_optional_text_frequency",
    configuration_page_optional_text_frequency_short_form = "configuration_page_optional_text_frequency_short_form",
    configuration_page_label_voltage = "configuration_page_label_voltage",
    configuration_page_label_rated_voltage = "configuration_page_label_rated_voltage",
    configuration_page_optional_text_voltage = "configuration_page_optional_text_voltage",
    configuration_page_label_rated_power = "configuration_page_label_rated_power",
    configuration_page_optional_text_rated_power = "configuration_page_optional_text_rated_power",
    configuration_page_optional_text_rated_power_short_form = "configuration_page_optional_text_rated_power_short_form",
    configuration_page_label_min_ahf_rating = "configuration_page_label_min_ahf_rating",
    configuration_page_label_rated_power_factor = "configuration_page_label_rated_power_factor",
    configuration_page_label_rated_power_factor_short_form = "configuration_page_label_rated_power_factor_short_form",
    configuration_page_optional_text_rated_power_factor = "configuration_page_optional_text_rated_power_factor",
    configuration_page_label_sub_transient_reactance_xd = "configuration_page_label_sub_transient_reactance_xd",
    configuration_page_label_sub_transient_reactance_xd_short_form = "configuration_page_label_sub_transient_reactance_xd_short_form",
    configuration_page_optional_text_sub_transient_reactance_xd = "configuration_page_optional_text_sub_transient_reactance_xd",
    configuration_page_motor_control_center_modal_popup_title = "configuration_page_motor_control_center_modal_popup_title",
    configuration_page_motor_control_center_modal_popup_edit_title = "configuration_page_motor_control_center_modal_popup_edit_title",
    configuration_page_label_mcc_name = "configuration_page_label_mcc_name",
    configuration_page_optional_text_mcc_name = "configuration_page_optional_text_mcc_name",
    configuration_page_non_linear_load_modal_popup_title = "configuration_page_non_linear_load_modal_popup_title",
    configuration_page_non_linear_load_modal_popup_edit_title = "configuration_page_non_linear_load_modal_popup_edit_title",
    configuration_page_non_linear_load_modal_popup_edit_header = "configuration_page_non_linear_load_modal_popup_edit_header",
    configuration_page_non_linear_load_modal_popup_header = "configuration_page_non_linear_load_modal_popup_header",
    configuration_page_label_application_name = "configuration_page_label_application_name",
    configuration_page_optional_text_application_name = "configuration_page_optional_text_application_name",
    configuration_page_label_quantity_of_drives = "configuration_page_label_quantity_of_drives",
    configuration_page_optional_text_quantity_of_drives = "configuration_page_optional_text_quantity_of_drives",
    configuration_page_label_drive = "configuration_page_label_drive",
    configuration_page_optional_text_drive = "configuration_page_optional_text_drive",
    configuration_page_label_additional_filter = "configuration_page_label_additional_filter",
    configuration_page_optional_text_additional_filter = "configuration_page_optional_text_additional_filter",
    configuration_page_label_maximum_load = "configuration_page_label_maximum_load",
    configuration_page_optional_text_maximum_load = "configuration_page_optional_text_maximum_load",
    configuration_page_label_cable_length = "configuration_page_label_cable_length",
    configuration_page_optional_text_cable_length = "configuration_page_optional_text_cable_length",
    configuration_page_powergrid_modal_popup_title = "configuration_page_powergrid_modal_popup_title",
    configuration_page_select_one_of_the_following_text = "configuration_page_select_one_of_the_following_text",
    configuration_page_label_mvasc = "configuration_page_label_mvasc",
    configuration_page_optional_text_mvasc = "configuration_page_optional_text_mvasc",
    configuration_page_label_isc = "configuration_page_label_isc",
    configuration_page_optional_text_isc = "configuration_page_optional_text_isc",
    configuration_page_label_feeder_r_and_x = "configuration_page_label_feeder_r_and_x",
    configuration_page_label_feeder_r = "configuration_page_label_feeder_r",
    configuration_page_label_feeder_x = "configuration_page_label_feeder_x",
    configuration_page_optional_text_feeder_r = "configuration_page_optional_text_feeder_r",
    configuration_page_optional_text_feeder_x = "configuration_page_optional_text_feeder_x",
    configuration_page_user_transformer_modal_popup_title = "configuration_page_user_transformer_modal_popup_title",
    configuration_page_user_transformer_modal_popup_edit_title = "configuration_page_user_transformer_modal_popup_edit_title",
    configuration_page_label_rated_kva = "configuration_page_label_rated_kva",
    configuration_page_optional_text_rated_kva = "configuration_page_optional_text_rated_kva",
    configuration_page_optional_text_rated_kva_inline_edit_popup = "configuration_page_optional_text_rated_kva_inline_edit_popup",
    configuration_page_label_secondary_voltage = "configuration_page_label_secondary_voltage",
    configuration_page_optional_text_secondary_voltage = "configuration_page_optional_text_secondary_voltage",
    configuration_page_optional_text_secondary_voltage_inline_edit_popup = "configuration_page_optional_text_secondary_voltage_inline_edit_popup",
    configuration_page_label_rated_impedence = "configuration_page_label_rated_impedence",
    configuration_page_label_rated_impedence_short_form = "configuration_page_label_rated_impedence_short_form",
    configuration_page_optional_text_rated_impedence = "configuration_page_optional_text_rated_impedence",
    configuration_page_label_configuration = "configuration_page_label_configuration",
    configuration_page_optional_text_configuration_choose = "configuration_page_optional_text_configuration_choose",
    configuration_page_optional_text_configuration = "configuration_page_optional_text_configuration",
    configuration_page_card_strip_active_harmonic_filter_title = "configuration_page_card_strip_active_harmonic_filter_title",
    configuration_page_label_rated_current = "configuration_page_label_rated_current",
    configuration_page_optional_text_rated_current = "configuration_page_optional_text_rated_current",
    configuration_page_card_strip_linear_loads_title = "configuration_page_card_strip_linear_loads_title",
    configuration_page_label_kva_loads = "configuration_page_label_kva_loads",
    configuration_page_optional_text_kva_loads = "configuration_page_optional_text_kva_loads",
    configuration_page_optional_text_kva_loads_max_percentage = "configuration_page_optional_text_kva_loads_max_percentage",
    configuration_page_label_motor_loads = "configuration_page_label_motor_loads",
    configuration_page_optional_text_motor_loads = "configuration_page_optional_text_motor_loads",
    configuration_page_label_soft_starter_loads = "configuration_page_label_soft_starter_loads",
    configuration_page_optional_text_soft_starter_loads = "configuration_page_optional_text_soft_starter_loads",
    configuration_page_label_soft_resistive_loads = "configuration_page_label_soft_resistive_loads",
    configuration_page_optional_text_resistive_loads = "configuration_page_optional_text_resistive_loads",
    configuration_page_label_soft_current_loads = "configuration_page_label_soft_current_loads",
    configuration_page_optional_text_current_loads = "configuration_page_optional_text_current_loads",
    configuration_page_card_strip_motor_control_center_title = "configuration_page_card_strip_motor_control_center_title",
    configuration_page_card_strip_transformer_title = "configuration_page_card_strip_transformer_title",
    configuration_page_assumption_notes_card_title = "configuration_page_assumption_notes_card_title",
    configuration_page_assumption_notes_card_select_from_the_list_button_text = "configuration_page_assumption_notes_card_select_from_the_list_button_text",
    configuration_page_label_harmonic_current = "configuration_page_label_harmonic_current",
    configuration_page_label_fundamental_current = "configuration_page_label_fundamental_current",
    configuration_page_label_total_power = "configuration_page_label_total_power",
    configuration_page_label_linear_loads = "configuration_page_label_linear_loads",
    configuration_page_label_total = "configuration_page_label_total",
    configuration_page_label_point_of_common_coupling = "configuration_page_label_point_of_common_coupling",
    configuration_page_label_drives = "configuration_page_label_drives",
    configuration_page_label_percentage_of_rated_source = "configuration_page_label_percentage_of_rated_source",
    configuration_page_harmonic_result_summary_title = "configuration_page_harmonic_result_summary_title",
    configuration_page_load_details_title = "configuration_page_load_details_title",
    configuration_page_assumption_notes_title = "configuration_page_assumption_notes_title",
    configuration_page_assumption_notes_card_label = "configuration_page_assumption_notes_card_label",
    configuration_page_assumption_notes_card_placeholder = "configuration_page_assumption_notes_card_placeholder",
    configuration_page_assumption_notes_card_optional_text = "configuration_page_assumption_notes_card_optional_text",
    configuration_page_create_report_button_label = "configuration_page_create_report_button_label",
    configuration_page_system_component_button_label = "configuration_page_system_component_button_label",
    configuration_page_result_summary_title_power_grid = "configuration_page_result_summary_title_power_grid",
    configuration_page_result_summary_sub_title_transformer_id_tag = "configuration_page_result_summary_sub_title_transformer_id_tag",
    configuration_page_result_summary_title_primary_side_of_transformer = "configuration_page_result_summary_title_primary_side_of_transformer",
    configuration_page_result_summary_title_secoundary_side_of_transformer = "configuration_page_result_summary_title_secoundary_side_of_transformer",
    configuration_page_result_summary_title_lv_genset = "configuration_page_result_summary_title_lv_genset",
    configuration_page_summary_card_sub_title = "configuration_page_summary_card_sub_title",
    configuration_page_summary_card_current_tdd_tool_tip = "configuration_page_summary_card_current_tdd_tool_tip",
    configuration_page_summary_card_voltage_thd_tool_tip = "configuration_page_summary_card_voltage_thd_tool_tip",
    configuration_page_summary_card_current_tdd_title = "configuration_page_summary_card_current_tdd_title",
    configuration_page_summary_card_voltage_thd_title = "configuration_page_summary_card_voltage_thd_title",
    configuration_page_summary_card_isc_i_ratio_title = "configuration_page_summary_card_isc_i_ratio_title",
    configuration_page_summary_card_label_limit = "configuration_page_summary_card_label_limit",
    configuration_page_summary_card_label_Recommendation = "configuration_page_summary_card_label_Recommendation",
    configuration_page_system_component_modal_popup_title = "configuration_page_system_component_modal_popup_title",
    configuration_page_system_component_modal_popup_header = "configuration_page_system_component_modal_popup_header",
    configuration_page_system_component_modal_popup_user_tranformer_item_title = "configuration_page_system_component_modal_popup_user_tranformer_item_title",
    configuration_page_system_component_modal_popup_linear_loads_item_title = "configuration_page_system_component_modal_popup_linear_loads_item_title",
    configuration_page_system_component_modal_popup_non_linear_loads_item_title = "configuration_page_system_component_modal_popup_non_linear_loads_item_title",
    configuration_page_system_component_modal_popup_active_harmonic_filter_item_title = "configuration_page_system_component_modal_popup_active_harmonic_filter_item_title",
    configuration_page_pcc_label = "configuration_page_pcc_label",
    report_page_email_modal_popup_title = "report_page_email_modal_popup_title",
    email_address = "email_address",
    enter_email_id = "enter_email_id",
    email_optional_text = "email_optional_text",
    email_success_notification = "email_success_notification",
    email_error_notification = "email_error_notification",
    email_error_notification_unauthorized = "email_error_notification_unauthorized",
    email_error_notification_service_unavailable = "email_error_notification_service_unavailable",
    email_error_notification_internal_server_error = "email_error_notification_internal_server_error",
    email_error_notification_bad_request = "email_error_notification_bad_request",
    email_error_notification_timout = "email_error_notification_timout",
    email_error_notification_too_many_requests = "email_error_notification_too_many_requests",
    send = "send",
    sending = "sending",
    click_here = "click_here",
    configuration_page_import_configuration_modal_popup_title = "configuration_page_import_configuration_modal_popup_title",
    configuration_page_import_configuration_modal_popup_uploaded_the_wrong_file_text = "configuration_page_import_configuration_modal_popup_uploaded_the_wrong_file_text",
    configuration_page_import_configuration_modal_popup_drag_and_drop_or_text = "configuration_page_import_configuration_modal_popup_drag_and_drop_or_text",
    configuration_page_import_configuration_modal_popup_click_here_text = "configuration_page_import_configuration_modal_popup_click_here_text",
    configuration_page_import_configuration_modal_popup_to_upload_file_text = "configuration_page_import_configuration_modal_popup_to_upload_file_text",
    configuration_page_import_configuration_modal_popup_please_try_uploading_again_text = "configuration_page_import_configuration_modal_popup_please_try_uploading_again_text",
    configuration_page_import_configuration_modal_popup_invalid_json_format_message = "configuration_page_import_configuration_modal_popup_invalid_json_format_message",
    configuration_page_import_configuration_modal_popup_success_notification_message = "configuration_page_import_configuration_modal_popup_success_notification_message",
    configuration_page_import_configuration_modal_popup_updation_failed_error_message = "configuration_page_import_configuration_modal_popup_updation_failed_error_message",
    drag_and_drop_maximium_count_exceeded_notification_message = "drag_and_drop_maximium_count_exceeded_notification_message",
    drag_and_drop_format_mismatch_notification_message = "drag_and_drop_format_mismatch_notification_message",
    download_excel_page_message = "download_excel_page_message",
    privacy_cookie_modal_title = "privacy_cookie_modal_title",
    privacy_cookie_modal_body = "privacy_cookie_modal_body",
    privacy_cookie_modal_body_link = "privacy_cookie_modal_body_link",
    privacy_cookie_analytics_title = "privacy_cookie_analytics_title",
    privacy_cookie_analytics_text = "privacy_cookie_analytics_text",
    privacy_cookie_preference_title = "privacy_cookie_preference_title",
    privacy_cookie_perferences_body = "privacy_cookie_perferences_body",
    app_footer_privacy_cookie = "app_footer_privacy_cookie",
    app_footer_legal = "app_footer_legal",
    privacy_cookie_chinese_concent = "privacy_cookie_chinese_concent",
    privacy_cookie_chinese_data_transfer = "privacy_cookie_chinese_data_transfer",
    info_page_legal_header = "info_page_legal_header",
    privacy_cookie_accept_selected_button = "privacy_cookie_accept_selected_button",
    privacy_cookie_accept_all_button = "privacy_cookie_accept_all_button",
    privacy_cookie_refuse_all_button = "privacy_cookie_refuse_all_button",
    app_footer_provider_information = "app_footer_provider_information",
    app_footer_acceptable_use_policy = "app_footer_acceptable_use_policy",
    app_footer_privacy_notice = "app_footer_privacy_notice",
}