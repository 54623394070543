import { Routes, Route } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import { NavigationPath } from "./utils/Navigation";
import MainLayout from "./layout/MainLayout";
import Home from "./pages/Home/index";
import Project from "./pages/Project";
import Configuration from "./pages/Configuration";
import Report from "./pages/Report";
import NotFound from "./pages/NotFound";
import store from "./store/store";
import Notification from "./components/Notification";
import { TranslateProvider } from "./i18translate";
import { TranslationConfig } from "./i18translate/models/TranslationConfig";
import Info from "./pages/Info";
import PrivateRoute from "./authServices/PrivateRoute";
import { AuthProvider } from "react-oidc-context";
import AuthConfig from "./authServices/AuthConfig";
import LoginCallback from "./authServices/LoginCallback";
import Login from "./authServices/Login";
import Logout from "./authServices/Logout";
import UnAuthorisedOrExcelDownloadPage from "./authServices/UnAuthorisedOrExcelDownloadPage";
import SessionTimeOut from "./authServices/SessionTimeOut";
import { useEffect } from "react";
import { AppEnvironments, checkAppEnvironment } from "./utils/AppEnvironments";

const translationConfig: TranslationConfig = {
  currentLanguage: "en-US",
  currentLanguageJson: require("./languages/en-US.json"),
  fallbackLanguage: "en-US",
  fallbackLanguageJson: require("./languages/en-US.json"),
};

function App() {
  //Removing all other environment expect production from search engines.
  useEffect(() => {
    if (!checkAppEnvironment(AppEnvironments.Production)) {
      const disableFromSEOTag = document.createElement("meta");
      disableFromSEOTag.name = "robots";
      disableFromSEOTag.content = "noindex";
      document.head.appendChild(disableFromSEOTag);
    }
  }, []);

  return (
    <>
      <StoreProvider store={store}>
        <TranslateProvider {...translationConfig}>
          <AuthProvider {...AuthConfig}>
            <Routes>
              <Route path="/" element={<MainLayout />}>
                <Route index element={<Home />} />
                <Route path={NavigationPath.Login} element={<Login />} />
                <Route path={NavigationPath.Logout} element={<Logout />} />
                <Route
                  path={`${NavigationPath.Logout}/${NavigationPath.Unauthorized}`}
                  element={<Logout />}
                />
                <Route
                  path={`${NavigationPath.Logout}/${NavigationPath.SessionTimeOut}`}
                  element={<Logout />}
                />
                <Route
                  path={NavigationPath.Unauthorized}
                  element={<UnAuthorisedOrExcelDownloadPage />}
                />
                <Route
                  path={NavigationPath.SessionTimeOut}
                  element={<SessionTimeOut />}
                />
                <Route
                  path={NavigationPath.LoginCallback}
                  element={<LoginCallback />}
                />
                {/* //? Restricting Quick Estimate so don't allow to access the config page directly. To allow quick estimate simply uncomment below part.  
                <Route path={NavigationPath.Configuration}
                  element={
                    <PrivateRoute>
                      <Configuration />
                    </PrivateRoute>}
                /> */}
                <Route
                  path={`${NavigationPath.Configuration}/:projectId/:configurationId`}
                  element={
                    <PrivateRoute>
                      <Configuration />
                    </PrivateRoute>
                  }
                />
                {[
                  NavigationPath.Project,
                  `${NavigationPath.Project}/:projectId`,
                ].map((pathItem) => (
                  <Route
                    key={pathItem}
                    path={pathItem}
                    element={
                      <PrivateRoute>
                        <Project />
                      </PrivateRoute>
                    }
                  />
                ))}
                <Route path={`${NavigationPath.Info}/*`} element={<Info />}>
                  <Route index element={<Info />} />
                  <Route
                    path={NavigationPath.VersionHistory}
                    element={<Info />}
                  />
                  <Route path={NavigationPath.Legal} element={<Info />} />
                </Route>
                <Route
                  path={`${NavigationPath.Report}/:projectId/:configurationId`}
                  element={
                    <PrivateRoute>
                      <Report />
                    </PrivateRoute>
                  }
                >
                  <Route
                    path={NavigationPath.ReportPrint}
                    element={<Report />}
                  />
                </Route>
                <Route
                  path={NavigationPath.DownloadExcelTool}
                  element={<UnAuthorisedOrExcelDownloadPage />}
                />
                <Route path="*" element={<NotFound />}></Route>
                <Route
                  path={`${NavigationPath.NotFound}/*`}
                  element={<NotFound />}
                />
              </Route>
            </Routes>
          </AuthProvider>
          <Notification id="root-component-notification" />
          <Notification id="root-component-alert" />
        </TranslateProvider>
      </StoreProvider>
    </>
  );
}

export default App;
